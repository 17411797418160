/* Profile.css */
.badge-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  min-width: 140px;
  margin-left: -5px;

}

@media (max-width: 868px) {
  .badge-container {
    flex-direction: column; /* Change to column on small screens */
    align-items: center; /* Center the badges vertically */
    margin: 0 auto;
    text-align: center;
  }
}
@media (max-width: 300px) {
   .badge-container {
     transform: translateX(4%); /* Translate horizontally by 4% */
   }
 }
